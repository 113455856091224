import { createSlice } from "@reduxjs/toolkit";

var initial = {
  post: {},
};

export const postSlice = createSlice({
  name: "post",
  initialState: initial,
  reducers: {
    loadPost: (state, action) => {
      state.post = action.payload;
    },
  },
});

export const { loadPost } = postSlice.actions;

export const selectpost = (state) => state.post.post;
export default postSlice.reducer;
