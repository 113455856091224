import React from "react";

import { Grid, Box } from "@mui/material";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { flexbox } from "@mui/system";

export default function PostLoader() {
  let card = [1, 2];
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <div style={{ margin: 15 }}>
          <Skeleton style={{ height: 245 }} />
          <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
            <Skeleton style={{ height: 36, width: 36, borderRadius: 50 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: 7,
              }}
            >
              <Skeleton style={{ height: 10, width: 200 }} count={2} />
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}
