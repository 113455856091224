import { createSlice } from "@reduxjs/toolkit";

var initial = {
  postDetail: {},
};

export const postDetailSlice = createSlice({
  name: "postDetail",
  initialState: initial,
  reducers: {
    loadPostDetail: (state, action) => {
      state.postDetail = action.payload;
    },
  },
});

export const { loadPostDetail } = postDetailSlice.actions;

export const selectPostDetail = (state) => state.postDetail.postDetail;
export default postDetailSlice.reducer;
