import React from "react";

import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./ImageCard.css";

export default function ImageCard({
  poster,
  theamColor,
  title,
  description,
  post_id,
  flows_slug,
  post_slug,
  post_name,
}) {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <div
        className="Home__ImgCard_Container"
        onClick={() => {
          navigate("/post/" + post_id + "/" + flows_slug + "/" + post_slug);
        }}
      >
        <div
          className="Home__ImgCard_Image_Box"
          style={{ /* background: "rgb(246 246 246)" */ background: "#E8F2F8" }}
        >
          <img src={poster} />
        </div>
        <div className="Home__ImageCard_info">
          <div
            className="Home__image_card_avator_holder"
            style={{ background: theamColor }}
          >
            {post_name.charAt(0)}
          </div>
          <div>
            <h4 className="Home__ImageCard_info_title">{title}</h4>
            <p className="Home__ImageCard_info_description">{description}</p>
          </div>
        </div>
      </div>
    </Grid>
  );
}
