import React,{useState,useCallback} from "react";
import ScreenshotCard from "./ScreenshotCard";
import ImageViewer from "react-simple-image-viewer";

import Divider from "../Divider";
import { Grid, Box } from "@mui/material";

export default function Screenshots({ item }) {

   const [currentImage, setCurrentImage] = useState(0);
   const [isViewerOpen, setIsViewerOpen] = useState(false);


  var type = item.device;

  var images = [];
  for (var i = 0; i < item.screenshots.length; i++){
    images.push(item.screenshots[i].url);
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  
  
  }, []);

   const closeImageViewer = () => {
     setCurrentImage(0);
     setIsViewerOpen(false);
  };
  

  return (
    <>
      <h3>Screenshots</h3>
      <Divider />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {item.screenshots.map(function (item, i) {
            return (
              <ScreenshotCard
                key={Date.now() / 1000 + i}
                image={item.url}
                title={item.title}
                device={type}
                index={i}
                openImageViewer={openImageViewer}
              />
            );
          })}

          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{ maxheight: "90%" }}
            />
          )}
        </Grid>
      </Box>
    </>
  );
}
