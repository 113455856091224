import React from 'react';
import Header from '../header/Header';

import { Box } from '@mui/system';
import Footer from "../footer/Footer"

export default function Wrapper({ children }) {
  const drawerWidth = 240;
  return (
    <>
      <Header />
      <div
        style={{
          height: "91vh",
          marginRight: 0,
          margin: 0,
          background: "#fff",
          overflowY: "auto",
        }}
      >
        <div style={{ padding: 20, width: "100%" }}>{children}</div>
        <Footer />
      </div>
    </>
  );
}


