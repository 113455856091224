import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";

export default function SideBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <a className="Header__Logo">
        <img src="https://designflow.sgp1.digitaloceanspaces.com/v1/assets/logo.jpg" />
      </a>
      <Divider />
      <List>
        <ListItem button>
          <NavLink
            to="#"
            style={{
              textDecoration: "none",
              color: " #3d3d3d",
              fontSize: "15px",
            }}
          >
            All
          </NavLink>
        </ListItem>

        <ListItem button>
          <NavLink
            to="#"
            style={{
              textDecoration: "none",
              color: " #3d3d3d",
              fontSize: "15px",
            }}
          >
            Desktop
          </NavLink>
        </ListItem>

        <ListItem button>
          <NavLink
            to="#"
            style={{
              textDecoration: "none",
              color: " #3d3d3d",
              fontSize: "15px",
            }}
          >
            Mobile
          </NavLink>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
   
        <React.Fragment key={'left'}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon style={{ color: "#000", fontSize: "34px" }} />
          </IconButton>
         
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
   
    </>
  );
}
