import { configureStore } from "@reduxjs/toolkit";

import postReducer from "../features/postSlice";
import postDetailSlice from "../features/postDetailSlice";


export default configureStore({
  reducer: {
    post: postReducer,
    postDetail: postDetailSlice,
  },
});
