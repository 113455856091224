import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
    return (
        
        <div style={{height:'100%',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <TailSpin />
        </div>
    )
}

export default Loader;