import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import { selectPostDetail } from "../../features/postDetailSlice";
import { loadPostDetail } from "../../features/postDetailSlice";
import { useDispatch } from "react-redux";
import PostInfo from "../../components/post/PostInfo";

import Wrapper from "../../components/wrapper/Wrapper";

import Timeline from "../../components/post/Timeline";

import Divider from "../../components/Divider";

import Screenshots from "../../components/post/Screenshots";
import Loader from "../../components/Loader";

export default function Post() {
  const { post_id } = useParams();
  const post = useSelector(selectPostDetail);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    axios
      .post("posts/" + post_id + "/detail")
      .then(function (response) {
        if (response.data.error === false) {
          dispatch(loadPostDetail(response.data.data));
          setLoading(false);
        } else {
        }
      })
      .catch(function (error) {
        return error;
      });
  }, [dispatch]);
  return (
    <>
      <Wrapper>
        {loading === false ? (
          <>
            {console.log(post)}
            <PostInfo item={post} />
            <Divider />
            {post.video_url !== null ? (
            
               
                <Timeline item={post} />
           
            ) : (
              <></>
            )}

            <Divider />
            <Screenshots item={post} device />
          </>
        ) : (
          <>
            {" "}
            <div style={{ height: "80vh", width: "100%" }}>
              <Loader />
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
}
