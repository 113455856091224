import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";





import Home from "./pages/Home";
import Post from "./pages/post/Post";




function App() {
 

 
  return (
    <BrowserRouter >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/:cat_id" element={<Home />} />
        <Route exact path="/post/:post_id/:flows_slug/:post_slug" element={<Post />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
