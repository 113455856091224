import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../api/axios";
import { selectpost } from "../features/postSlice";
import { useDispatch } from "react-redux";
import { loadPost } from "../features/postSlice";
import Wrapper from "../components/wrapper/Wrapper";
import ImageCard from "../components/home/ImageCard";
import { Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./Home.css";

import PostLoader from "../components/skeleton/PostLoader";

export default function Home() {
  
  const { cat_id } = useParams();

  const dispatch = useDispatch();
  const posts = useSelector(selectpost);
  
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  useEffect(() => {
    setLoading(true);
    var currentCategory = "all";
    if (cat_id !== undefined) {
      currentCategory = cat_id;
    }
    axios
      .post("posts/" + currentCategory + "?page=" + currentPage)
      .then(function (response) {
        if (response.data.error === false) {
          dispatch(loadPost(response.data));
          setLoading(false);
        } else {
        }
      })
      .catch(function (error) {
        return error;
      });
  }, [dispatch, currentPage, cat_id]);
  return (
    <div>
      <Wrapper>
        <div className="Home__bar">
          <h4
            style={{
              marginBottom: " 31px",
              fontSize: "20px",
              marginTop: "17px",
            }}
          >
            {posts.category}
          </h4>
          <div className="Header__Searchbar">
            <div className="Header__Searchbar_holder">
              <SearchIcon style={{ color: "black" }} />
              <div className="Header__Searchbar_holder_input">
                <input type="text" placeholder="Search your flows.." />
              </div>
            </div>
          </div>
        </div>

        <Box sx={{ flexGrow: 1, minHeight: "100vh" }}>
          <Grid container>
            {loading === false ? (
              <>
                {posts.data.data.length !== 0 ? (
                  <>
                    {posts.data.data.map(function (item, i) {
                      return (
                        <ImageCard
                          key={i}
                          poster={item.screenshot[0].url}
                          theamColor="#8affd0"
                          title={item.flows + " on " + item.product}
                          description={
                            item.total_screenshot + " " + "Screenshots"
                          }
                          post_id={item.id}
                          flows_slug={item.flows_slug}
                          post_slug={item.product_slug}
                          post_name={item.product}
                        />
                      );
                    })}
                  </>
                ) : (
                    <>
                      <div style={{height:'70vh',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                         <h4>No data found</h4>
                      </div>
                    </>
                )}
                {/*
                 */}
              </>
            ) : (
              <>
                <PostLoader />
              </>
            )}
          </Grid>
        </Box>
      </Wrapper>
    </div>
  );
}
