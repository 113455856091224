import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";

import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import CColors from "../theme";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

import SideBar from "../sidebar/Sidebar";

const Header = (props) => {
  const classes = useStyles();

 

  return (
    <AppBar
      elevation={0}
      className={classes.root}
      position="static"
      sx={{
        background: CColors.theam,
        borderBottom: "1px solid rgb(247, 247, 247)",
        position: "sticky",
        top: "0px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className="Header">
            <div className="Header__left_section">
              {/*  <a className="Header__Logo">
                <img src="https://designflow.sgp1.digitaloceanspaces.com/v1/assets/logo.jpg" />
              </a> */}
              <div className="Header__Menus">
                <Link to={"/"}>All</Link>
                <Link to={"/desktop"}>Desktop</Link>
                <Link to={"/mobile"}>Mobile</Link>
                
              </div>
            </div>

            <div className="Header_right_section">
              <div className="Header__Handburger_Menu">
                <SideBar />
              </div>
            </div>
          </div>
        </Toolbar>

        {/*  side bar */}
      </Container>
    </AppBar>
  );
};
export default Header;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}));
