import React,{useState} from "react";
import "./TimelineCard.css";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import IconButton from "@mui/material/IconButton";

export default function TimelineCard({ item, video, videoPlayerController }) {
  const SecondsToMinutes = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + parseInt(s);
  };

  const [playing, setPlaying] = useState(true);

  const playerHandler = () => {
    setPlaying(!video.current.player.isPlaying);
    videoPlayerController(!video.current.player.isPlaying);
  };

  return (
    <div className="Timelinecard">
      <div className="Timelinecard__player_controll">
        <IconButton
          onClick={() => {
            playerHandler();
          }}
          color="primary"
          style={{ height: "57px", width: " 57px" }}
        >
          {playing === true ? (
            <>
              <PauseCircleIcon style={{ fontSize: 43 }} />
            </>
          ) : (
            <>
              <PlayCircleIcon style={{ fontSize: 43 }} />
            </>
          )}
        </IconButton>
      </div>
      <div className="Timelinecard__seekcard_holder">
        {item.map(function (data, i) {
          return (
            <div
              key={i}
              className="Timelinecard__seekcard"
              onClick={() => video.current.seekTo(data.seekTime)}
            >
              <div className="Timelinecard__seekbar_avator">
                <span>{SecondsToMinutes(data.seekTime)}</span>
              </div>
              <div className="Timelinecard__title">
                <span>{data.title}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
