import React from 'react'
import "./ScreenshotsCard.css";
import { Grid, Box } from "@mui/material";

export default function ScreenshotCard({ image, title, device, index, openImageViewer }) {
  const clickParentFunction = () => {
    openImageViewer(index);
  };
  return (
    <Grid
      item
      xs={12}
      sm={device === "desktop" ? 12 : 6}
      md={device === "desktop" ? 6 : 4}
      lg={device === "desktop" ? 6 : 3}
    >
      <div
        className="ScreenshotCard_container"
        style={{ maxWidth: device === "desktop" ? "auto" : "400px" }}
      >
        <div
          className="ScreenshotCard__image_holder"
          style={{ paddingBottom: device === "desktop" ? "63.4%" : "216%" }}
        >
          <img src={image} onClick={() => clickParentFunction()} />
        </div>
        <div className="ScreenshotCard__title_holder">
          <h6>{title}</h6>
        </div>
      </div>
    </Grid>
  );
}
