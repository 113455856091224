import React,{useState} from "react";

import "./Timeline.css";

import ReactPlayer from "react-player";
import { Grid } from "@mui/material";
import TimelineCard from "./TimelineCard";

export default function Timeline({ item }) {
  const ref = React.createRef();
  const [isPlaying, setIsplaying] = useState(true);

  const videoPlayerController = (data) => {
    setIsplaying(data);
  }
 
  return (
    <div className="Post__container">
      <Grid container>
        <Grid className="Post__container_s1" item xs={12} sm={12} md={8} lg={8}>
          <ReactPlayer
            ref={ref}
            className="react-player Post__timeline_player"
            url={item.video_url}
            width={item.device === "desktop" ? "100%" : 277.8}
            height={item.device === "desktop" ? "100%" : 600}
            controls={true}
            playing={isPlaying}
          />
        </Grid>
        <Grid className="Post__container_s2" item xs={12} sm={12} md={4} lg={4}>
          <TimelineCard
            item={JSON.parse(JSON.parse(item.timeline))}
            video={ref}
            videoPlayerController={videoPlayerController}
          />
        </Grid>
      </Grid>
    </div>
  );
}
