import React from 'react'

import "./Footer.css"

export default function Footer() {
  return (
    <div className="Footer">
     {/*  <img src="https://designflow.sgp1.digitaloceanspaces.com/v1/assets/logo.jpg" /> */}
      <div className='Footer_Menu'>
        <a href="#">Contact</a>
        <a href="#">About</a>
        <a href="#">Privacy Policy</a>
      </div>
    </div>
  );
}
