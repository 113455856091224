import React from 'react'
import "./PostInfo.css"
export default function PostInfo({item}) {
  return (
    <div className="Post__info">
      <div className="Post__info_avatar">
        <span>S</span>
      </div>
      <div className="Post__info_title_holder">
        <div className="Post__info_title">
          {item.flows + " On " + item.product}
        </div>
        <div className="Post__info_description">
          {item.screenshots.length+" "}Screenshots
        </div>
      </div>
    </div>
  );
}
